import { Component } from '@angular/core';
import { ChannelAddress, CurrentData, EdgeConfig, Utils } from '../../../../shared/shared';
import { AbstractFlatWidget } from 'src/app/shared/genericComponents/flat/abstract-flat-widget';
import { Evcs_ClusterModalComponent } from './modal/modal.component';

@Component({
    selector: 'Evcs_Cluster',
    templateUrl: './Evcs_Cluster.component.html'
})
export class Evcs_ClusterComponent extends AbstractFlatWidget {

    public clusterComponent: EdgeConfig.Component = null;
    public evcssInCluster: EdgeConfig.Component[] = [];
    public imageUrl: string;

    public evcsSumOfChargePower: number;
    public readonly CONVERT_WATT_TO_KILOWATT = Utils.CONVERT_WATT_TO_KILOWATT;

    protected getChannelAddresses() {

        let channelAddresses: ChannelAddress[] = [];

        for (let componentId of this.config.getComponent(this.componentId).properties["evcs.ids"]) {
            let component = this.config.getComponent(componentId);
            if (component.isEnabled) {
                this.evcssInCluster.push(component);
                this.fillChannelAddresses(component.id, channelAddresses);
            }
        }

        this.evcssInCluster.sort((c1, c2) => c1.alias.localeCompare(c2.alias));

        channelAddresses.push(
            new ChannelAddress(this.componentId, 'ChargePower'),
            new ChannelAddress(this.componentId, 'Phases'),
            new ChannelAddress(this.componentId, 'Plug'),
            new ChannelAddress(this.componentId, 'Status'),
            new ChannelAddress(this.componentId, 'State'),
            new ChannelAddress(this.componentId, 'EnergySession'),
            new ChannelAddress(this.componentId, 'MinimumHardwarePower'),
            new ChannelAddress(this.componentId, 'MaximumHardwarePower'),

            new ChannelAddress(this.componentId, 'ClusterState'),
            new ChannelAddress(this.componentId, 'EvcsPowerLimitUnprioritized'),
            new ChannelAddress(this.componentId, 'EvcsPowerLimitPrioritized'),

        )
        return channelAddresses;
    }

    protected onCurrentData(currentData: CurrentData) {

        let clusterState: number = currentData.allComponents[this.componentId + '/ClusterState'];
        this.imageUrl = "assets/img/oEMS/trafficlight.svg";
        switch (clusterState) {
            case 0: {
                this.imageUrl = "assets/img/oEMS/trafficlight_green.svg";
                break;
            }
            case 1: {
                this.imageUrl = "assets/img/oEMS/trafficlight_yellow.svg";
                break;
            }
            case 2: {
                this.imageUrl = "assets/img/oEMS/trafficlight_red.svg";
                break;
            }
        }

        this.clusterComponent = this.config.getComponent(this.componentId);

        this.evcsSumOfChargePower = 0;
        // Iterate over evcsComponents to get ChargePower for every component and calculate sum
        for (let component of this.evcssInCluster) {
            if (currentData.allComponents[component.id + '/ChargePower']) {
                this.evcsSumOfChargePower += currentData.allComponents[component.id + '/ChargePower'];
            }
        }
    }

    private fillChannelAddresses(componentId: string, channelAddresses: ChannelAddress[]) {
        channelAddresses.push(
            new ChannelAddress(componentId, 'ChargePower'),
            new ChannelAddress(componentId, 'MaximumHardwarePower'),
            new ChannelAddress(componentId, 'MinimumHardwarePower'),
            new ChannelAddress(componentId, 'MaximumPower'),
            new ChannelAddress(componentId, 'Phases'),
            new ChannelAddress(componentId, 'Plug'),
            new ChannelAddress(componentId, 'Status'),
            new ChannelAddress(componentId, 'State'),
            new ChannelAddress(componentId, 'EnergySession'),
            new ChannelAddress(componentId, 'Alias')
        )
    }

    async presentModal() {
        const modal = await this.modalController.create({
            component: Evcs_ClusterModalComponent, cssClass: "fullHeight",
            componentProps: {
                edge: this.edge,
                config: this.config,
                controller: this.clusterComponent,
                evcss: this.evcssInCluster,
                evcsSumOfChargePower: this.evcsSumOfChargePower,

            }
        });
        return await modal.present();
    }
}