import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PreInstallationModalComponent } from './pre_installation.modal/modal.component';
import { InstallationComponent } from './installation.component';

@NgModule({
    declarations: [
        InstallationComponent,
        PreInstallationModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        InstallationComponent
    ]
})
export class InstallationModule { }