import { Component, Input, OnInit } from '@angular/core';
import { Edge, Service, Websocket, EdgeConfig } from '../../../../../../shared/shared';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './modal.component.html'
})
export class Evcs_ClusterPrioModalComponent implements OnInit {

  @Input() public edge: Edge;
  @Input() public component: EdgeConfig.Component;
  @Input() public controller: EdgeConfig.Component;

  public hasPrio: Boolean;

  constructor(
    public service: Service,
    protected translate: TranslateService,
    public modalCtrl: ModalController,
    public router: Router,
    public websocket: Websocket,
  ) { }

  ngOnInit() {
    console.log("Init");
    this.hasPrio = this.controller.properties["prio.evcs.ids"].includes(this.component.id);
  }

  /**  
   * Updates the 'prio.evcs.ids'-Property of the "oEMS.Evcs.Cluster.PeakShaving.Prioritization"-Controller.
   * 
   * @param event 
   */
  updateMode(event: CustomEvent) {
    let newPrioIds = Object.assign([], this.controller.properties["prio.evcs.ids"]);

    let newMode = (event.detail.value.toLowerCase() === 'true');
    if (newMode) {
      newPrioIds.push(this.component.id)
    } else {
      newPrioIds.forEach((element, index) => {
        if (element == this.component.id) newPrioIds.splice(index, 1);
      });
    }

    this.edge.updateComponentConfig(this.websocket, this.controller.id, [
      { name: 'prio.evcs.ids', value: newPrioIds }
    ]).then(() => {
      this.controller.properties["prio.evcs.ids"] = newPrioIds;
      this.service.toast(this.translate.instant('General.changeAccepted'), 'success');
      this.modalCtrl.dismiss();
    }).catch(reason => {
      this.service.toast(this.translate.instant('General.changeFailed') + '\n' + reason.error.message, 'danger');
      console.warn(reason);
    });


  }
}