import { Component, Input, OnInit } from '@angular/core';
import { Edge, Service, EdgeConfig } from '../../../../../shared/shared';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Evcs_ClusterPrioModalComponent } from './prioModal/modal.component';
import { Controller_EvcsModalComponent } from '../../../../../edge/live/Controller/Evcs/modal/modal.page';

@Component({
    // selector: 'Evcs_ClusterModal',
    templateUrl: './modal.component.html'
})
export class Evcs_ClusterModalComponent implements OnInit {

    // inspired by modals for: consumption.component.ts and Evcs.ts
    @Input() public edge: Edge;
    @Input() public config: EdgeConfig;
    @Input() public evcss: EdgeConfig.Component[];
    @Input() public controller: EdgeConfig.Component;
    @Input() public evcsSumOfChargePower: number;

    public allowPrioritization: boolean = false;

    public controllerEvcs: EdgeConfig.Component = null;

    constructor(
        public service: Service,
        public modalController: ModalController,
        protected translate: TranslateService,
    ) { }

    ngOnInit() {
        if (this.controller != null) {
            if (this.controller.properties.allowPrioritization) {
                this.allowPrioritization = this.controller.properties.allowPrioritization;
            }
            let controllerEvcsComponents: EdgeConfig.Component[] = this.config.getComponentsByFactory('Controller.Evcs').filter(component => component.isEnabled);
            this.controllerEvcs = controllerEvcsComponents.filter(component => component.properties['evcs.id'] === this.controller.id)[0]
        }
    }

    hasPrio(evcsId: number) {
        if (this.controller.properties["prio.evcs.ids"].includes(evcsId)) {
            return true;
        } else {
            return false;
        }
    }


    /**
    * Returns the state of the EVCS
    * 
    * @param state 
    * @param plug 
    * 
    */
    getState(state: number, plug: number) {

        if (this.controller != null) {
            if (this.controller.properties.enabledCharging != null && this.controller.properties.enabledCharging == false) {
                return this.translate.instant('Edge.Index.Widgets.EVCS.chargingStationDeactivated');
            }
        }
        let chargeState = state;
        let chargePlug = plug;

        if (chargePlug == null) {
            if (chargeState == null) {
                return this.translate.instant('Edge.Index.Widgets.EVCS.notCharging');
            }
        } else if (chargePlug != ChargePlug.PLUGGED_ON_EVCS_AND_ON_EV_AND_LOCKED) {
            return this.translate.instant('Edge.Index.Widgets.EVCS.cableNotConnected');
        }
        switch (chargeState) {
            case ChargeState.STARTING:
                return this.translate.instant('Edge.Index.Widgets.EVCS.starting');
            case ChargeState.ERROR:
                return this.translate.instant('Edge.Index.Widgets.EVCS.error');
            case ChargeState.READY_FOR_CHARGING:
                return this.translate.instant('Edge.Index.Widgets.EVCS.readyForCharging');
            case ChargeState.NOT_READY_FOR_CHARGING:
                return this.translate.instant('Edge.Index.Widgets.EVCS.notReadyForCharging');
            case ChargeState.AUTHORIZATION_REJECTED:
                return this.translate.instant('Edge.Index.Widgets.EVCS.notCharging');
            case ChargeState.UNDEFINED:
                return this.translate.instant('Edge.Index.Widgets.EVCS.unknown');
            case ChargeState.CHARGING:
                return this.translate.instant('Edge.Index.Widgets.EVCS.charging');
            case ChargeState.ENERGY_LIMIT_REACHED:
                return this.translate.instant('Edge.Index.Widgets.EVCS.chargeLimitReached');
            case ChargeState.CHARGING_FINISHED:
                return this.translate.instant('Edge.Index.Widgets.EVCS.carFull');
        }
    }

    /**
    * Round to 100 and 
    * Round up (ceil)
    * 
    * @param i 
    */
    formatNumber(i: number) {
        let round = Math.ceil(i / 100) * 100;
        return round;
    }

    async presentPrioModal(component: EdgeConfig.Component) {
        if (this.allowPrioritization === true) {
            const modal = await this.modalController.create({
                component: Evcs_ClusterPrioModalComponent, cssClass: "fullHeight",
                componentProps: {
                    component: component,
                    edge: this.edge,
                    controller: this.controller,
                }
            });
            return await modal.present();
        } else {
            return;
        }
    }

    async presentSettingsModal() {
        const modal = await this.modalController.create({
            component: Controller_EvcsModalComponent, cssClass: "fullHeight",
            componentProps: {
                controller: this.controllerEvcs,
                edge: this.edge,
                componentId: this.controller.id,
                evcsComponent: this.controller,
                // getState: this.getState
            }
        });
        return await modal.present();
    }
}

enum ChargeState {
    UNDEFINED = -1,           //Undefined
    STARTING,                 //Starting
    NOT_READY_FOR_CHARGING,   //Not ready for Charging e.g. unplugged, X1 or "ena" not enabled, RFID not enabled,...
    READY_FOR_CHARGING,       //Ready for Charging waiting for EV charging request
    CHARGING,                 //Charging
    ERROR,                    //Error
    AUTHORIZATION_REJECTED,   //Authorization rejected
    ENERGY_LIMIT_REACHED,     //Energy limit reached
    CHARGING_FINISHED         //Charging has finished
}

enum ChargePlug {
    UNDEFINED = -1,                           //Undefined
    UNPLUGGED,                                //Unplugged
    PLUGGED_ON_EVCS,                          //Plugged on EVCS
    PLUGGED_ON_EVCS_AND_LOCKED = 3,           //Plugged on EVCS and locked
    PLUGGED_ON_EVCS_AND_ON_EV = 5,            //Plugged on EVCS and on EV
    PLUGGED_ON_EVCS_AND_ON_EV_AND_LOCKED = 7  //Plugged on EVCS and on EV and locked
}