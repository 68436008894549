import { Component } from '@angular/core';
import { ChannelAddress, CurrentData, EdgeConfig, Utils } from 'src/app/shared/shared';
import { AbstractFlatWidget } from 'src/app/shared/genericComponents/flat/abstract-flat-widget';
import { ModalComponent } from '../modal/modal';

@Component({
  selector: 'consumption',
  templateUrl: './flat.html'
})
export class FlatComponent extends AbstractFlatWidget {

  public evcss: EdgeConfig.Component[] | null = null;
  public consumptionMeters: EdgeConfig.Component[] = null;
  public sumActivePower: number = 0;
  public evcsSumOfChargePower: number;
  public otherPower: number;
  public readonly CONVERT_WATT_TO_KILOWATT = Utils.CONVERT_WATT_TO_KILOWATT;

  // oEMS - START
  public clusters: { cluster: EdgeConfig.Component; chargePower: number }[] = [];
  public clusteredComponentIds: String[] = [];
  // oEMS - END

  protected override getChannelAddresses() {

    // oEMS - START
    for (let cluster of this.config
      .getComponentsImplementingNature("io.openems.edge.evcs.api.MetaEvcs")
      .filter(component => component.isEnabled)) {
      let evcsIds = cluster.properties["evcs.ids"];
      let isConsumer = false;
      for (let evcsId of evcsIds) {
        let component = this.config.getComponent(evcsId);
        if (component.isEnabled && !this.config.getComponentsImplementingNature("io.openems.edge.evcs.api.MetaEvcs").includes(component)) {
          this.clusteredComponentIds.push(evcsId);
          isConsumer = true; // if cluster contains at least one physical evcs, it is treated as a consumer
        }
      }
      if (isConsumer) {
        this.clusters.push({ cluster: cluster, chargePower: 0 });
      }
    }
    // oEMS - END

    let channelAddresses: ChannelAddress[] = [
      new ChannelAddress('_sum', 'ConsumptionActivePower'),

      // TODO should be moved to Modal
      new ChannelAddress('_sum', 'ConsumptionActivePowerL1'),
      new ChannelAddress('_sum', 'ConsumptionActivePowerL2'),
      new ChannelAddress('_sum', 'ConsumptionActivePowerL3')
    ];

    // Get consumptionMeterComponents
    this.consumptionMeters = this.config.getComponentsImplementingNature("io.openems.edge.meter.api.ElectricityMeter")
      .filter(component => component.isEnabled && this.config.isTypeConsumptionMetered(component));

    for (let component of this.consumptionMeters) {
      channelAddresses.push(
        new ChannelAddress(component.id, 'ActivePower'),
        new ChannelAddress(component.id, 'ActivePowerL1'),
        new ChannelAddress(component.id, 'ActivePowerL2'),
        new ChannelAddress(component.id, 'ActivePowerL3')
      );
    }

    // Get EVCSs
    this.evcss = this.config.getComponentsImplementingNature("io.openems.edge.evcs.api.Evcs")
      .filter(component => !(component.factoryId == 'Evcs.Cluster.SelfConsumption') &&
        !(component.factoryId == 'Evcs.Cluster.PeakShaving') && !(component.factoryId == 'Evcs.Cluster.LoadManagement') && !component.isEnabled == false); // oEMS

    for (let component of this.evcss) {
      channelAddresses.push(
        new ChannelAddress(component.id, 'ChargePower')
      );
    }
    return channelAddresses;
  }

  protected override onCurrentData(currentData: CurrentData) {

    this.evcsSumOfChargePower = 0;
    let consumptionMetersSumOfActivePower: number = 0;
    this.sumActivePower = currentData.allComponents['_sum/ConsumptionActivePower'];

    // TODO move sums to Model
    // Iterate over evcsComponents to get ChargePower for every component
    for (let component of this.evcss) {
      if (currentData.allComponents[component.id + '/ChargePower']) {
        this.evcsSumOfChargePower += currentData.allComponents[component.id + '/ChargePower'];
      }
    }

    // Iterate over evcsComponents to get ChargePower for every component
    for (let component of this.consumptionMeters) {
      if (currentData.allComponents[component.id + '/ActivePower']) {
        consumptionMetersSumOfActivePower += currentData.allComponents[component.id + '/ActivePower'];
      }
    }

    this.otherPower = Utils.subtractSafely(this.sumActivePower,
      Utils.addSafely(this.evcsSumOfChargePower, consumptionMetersSumOfActivePower));

    // oEMS - START
    for (let index in this.clusters) {
      let chargePower: number = 0;
      for (let evcsId of this.clusters[index].cluster.properties["evcs.ids"]) {
        if (this.config.getComponent(evcsId).isEnabled) {
          chargePower = Utils.addSafely(chargePower, currentData.allComponents[evcsId + '/ChargePower']);
        }
      }
      this.clusters[index] = { cluster: this.clusters[index].cluster, chargePower: chargePower }
    }
    // oEMS - END
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: ModalComponent
    });
    return await modal.present();
  }
}