import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AddEdgeToUserRequest } from 'src/app/shared/jsonrpc/request/addEdgeToUserRequest';
import { AddEdgeToUserResponse } from 'src/app/shared/jsonrpc/response/addEdgeToUserResponse';
import { GetEdgeRequest } from 'src/app/shared/jsonrpc/request/getEdgeRequest';
import { GetEdgeResponse } from 'src/app/shared/jsonrpc/response/getEdgeResponse';
import { Edge, Service, Websocket } from 'src/app/shared/shared';
import { Router } from '@angular/router';
import { environment } from '../../../../environments';

@Component({
  selector: 'preinstallation-modal',
  templateUrl: './modal.component.html'
})
export class PreInstallationModalComponent implements OnInit {

  protected formGroup: FormGroup;
  public edge: Edge = null;
  public serialNumber: string = null;
  public environment = environment;

  constructor(
    private formBuilder: FormBuilder,
    public modalCtrl: ModalController,
    private translate: TranslateService,
    private service: Service,
    private websocket: Websocket,
    private router: Router,
  ) { }

  ngOnInit() {
    this.formGroup = this.getForm()
  }

  /**
   * Update the form depending on the thrown event (ionChange) value.
   * 
   * @param event to get current value and change the form
   */
  updateInstallationForm(event: CustomEvent) {
    this.formGroup = this.getForm();
  }

  /**
   * Validate the current form and sends the AddEdgeToUserRequest request.
   */
  onSubmit() {
    if (!this.formGroup.valid) {
      this.service.toast(this.translate.instant("Register.errors.requiredFields"), 'danger');
      return;
    }

    let addRequest = new AddEdgeToUserRequest({
      setupPassword: this.formGroup.value.setupPassword,
    });

    this.websocket.sendRequest(addRequest)
      .then(r => {
        let addResponse = (r as AddEdgeToUserResponse).result;
        this.edge = new Edge(addResponse.edge.id, addResponse.edge.comment, addResponse.edge.producttype, addResponse.edge.version, null, addResponse.edge.online, null);
        this.serialNumber = addResponse.serialNumber?.toString() || '-';
        this.service.toast(this.translate.instant("INSTALLATION.PRE_INSTALLATION.INSTALLATION_SUCCESS", { edgeId: this.edge.id }), 'success');

        let getEdgeRequest = new GetEdgeRequest({
          edgeId: addResponse.edge.id,
        })

        this.websocket.sendRequest(getEdgeRequest)
          .then(edge => {
            let getEdgeResponse = (edge as GetEdgeResponse).result;
            this.edge = getEdgeResponse.edge; // Hint: GetEdgeResponse returns as edge.role a string instead of a string instead of a Role.
          })
          .catch(reason => {
            this.service.toast(reason.error.message, 'danger');
          });
      })
      .catch(reason => {
        this.service.toast(reason.error.message, 'danger');
      });
  }

  /**
   * 
   * 
   */
  private getForm(): FormGroup {
    return this.formBuilder.group({
      setupPassword: new FormControl("", Validators.required),
    });
  }

  openAppCenter() {
    this.router.navigate(['/device', this.edge.id, 'settings', 'app']);
    this.modalCtrl.dismiss();
  }

}
