import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../environments';
import { PreInstallationModalComponent } from './pre_installation.modal/modal.component';

@Component({
    selector: 'installation',
    templateUrl: './installation.component.html'
})
export class InstallationComponent {

    public environment = environment;

    constructor(private modalController: ModalController) { }

    async presentModal() {
        const modal = await this.modalController.create({
            component: PreInstallationModalComponent, cssClass: "fullHeight"
        });
        return await modal.present();
    }

}